import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { throwIfAlreadyLoaded } from '../shared/functions/module-import-guard';
import { AppConfigService, initConfigService } from './app-config';
import { DataRepositoryService } from '../repositories/data-repository/data-repository.service';
// import { ConnectionService } from 'ng-connection-service';
import { DataRefreshService } from '../repositories/data-refresh-service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    // Note: To see correct file names and line numbers run the "ng serve" command with the "--evalSourceMap=true" option.
  ],
  exports: [],
  declarations: [],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfigService,
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: DataRepositoryService,
      useClass: DataRepositoryService
    },
    {
      provide: DataRefreshService,
      useClass: DataRefreshService
    }
  ]
})
export class CoreModule {
  public constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule is already loaded. Import it in the AppModule only');
  }

  /**
   * Providing a static ModuleWithProviders object containing all the services that should be singletons
   */
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [Title, AppConfigService]
    };
  }
}
