import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSn5Ud0RhWHtfdXNcQg==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUVkWH5ccXBUQ2lfVQ==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjXn5fcnVRR2ZcWEA=;MTI3MzE4MEAzMjMwMmUzNDJlMzBiNUpsODdFRmRZMzY5aEdrNU1meGRNVTFhYVk4WHFMdlV3cER2WGwyUFBRPQ==;MTI3MzE4MUAzMjMwMmUzNDJlMzBpY3ZMdVFlSU0zSzZJR0NOVEl3Wmp1Z2liTmlva1Q5cmZCM2ZFQTM1TWlvPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVgWXhfcXZUQmFaWERy;MTI3MzE4M0AzMjMwMmUzNDJlMzBGbElkL0NYUlRiekJhNTFaZC8wYk9iTytUN3Bia2o4Q0dEMGFDemc5MWcwPQ==;MTI3MzE4NEAzMjMwMmUzNDJlMzBjN2dEOE5WOU55djA4WE9wM0E1QWJPWTR4Qm5xd3FYckV6YjVHYit5N1NnPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjXn5fcnVRR2hdUkA=;MTI3MzE4NkAzMjMwMmUzNDJlMzBNdUpUcFJuWVVHRGVkcFNma3JyZWNRSDJxcy9aU0xla1p3T2tQODJWclBrPQ==;MTI3MzE4N0AzMjMwMmUzNDJlMzBmeGtOeFNlR1lyYkhubjhZWU5WUEI3UmVJYlhHM0tiMERmMlgreDdPWS9vPQ==;MTI3MzE4OEAzMjMwMmUzNDJlMzBGbElkL0NYUlRiekJhNTFaZC8wYk9iTytUN3Bia2o4Q0dEMGFDemc5MWcwPQ==');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
