import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from '@core/app-config';
import { take, distinctUntilChanged } from 'rxjs/operators';
import { HiveMeasurements } from '@shared/entities/hive-measurements';
import { DataRepositoryService } from './data-repository/data-repository.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DataRefreshService {

  public LastRefreshTime: Date;
  public SecondsUntilRefresh$ = new BehaviorSubject<Number>(300);
  public HiveData$ = new BehaviorSubject<HiveMeasurements>(null);

    private automaticRefreshSeconds = 300;
    public TimeToRefresh = 0;

    public constructor(
        private dataRepositoryService: DataRepositoryService,
        private appConfigService: AppConfigService
        ) {

          const appConfig$ = this.appConfigService.settings$.pipe(distinctUntilChanged());

          const subscription = appConfig$.pipe(take(1))
            .subscribe(settings => {
              if (settings.environment.automaticRefreshSeconds
                && settings.environment.automaticRefreshSeconds > 0) {
                this.automaticRefreshSeconds = settings.environment.automaticRefreshSeconds;
              }
          });
          subscription.unsubscribe();

        setInterval(() => {
            if (this.TimeToRefresh <= 0 || this.requireRefresh()) {
              this.RefreshData();
            } else {
              this.TimeToRefresh--;
            }
            this.SecondsUntilRefresh$.next(this.TimeToRefresh);
          }, 1000);
    }

    public RefreshData(): void {
      // take one will lead to unsubscripe afterwards automatically
      this.dataRepositoryService.getHiveData().pipe(take(1))
      .subscribe(data => {
        if(data) {
          this.LastRefreshTime = new Date();
          this.HiveData$.next(data);
        }
      });
      this.TimeToRefresh = this.automaticRefreshSeconds;
    }

    private requireRefresh(): boolean {
      if (this.LastRefreshTime) {
        const diff = moment(new Date()).diff(this.LastRefreshTime, 'seconds');
        if(diff > this.automaticRefreshSeconds) {
          return true;
        }
      }
      return false;
    }
}
