export class HiveMeasurementEntry {
  public entryId?: number;
  public date: Date;
  public value: number;
}

export function CopyHiveData(input: HiveMeasurementEntry[]): HiveMeasurementEntry[]  {
  const result: HiveMeasurementEntry[] = [];
  input.forEach(i =>  {
      result.push({entryId: i.entryId, date: i.date, value: i.value });
  });

  return result;
}
