import { Channel } from './channel';
import { HiveMeasurementEntry } from './hive-measurement-entry';

export class HiveMeasurements {
  public channel: Channel;
  public firstUpdateDate: Date;
  public lastUpdateDate: Date;
  public currentTemperatur: Number;
  public currentHiveTemperatur: Number;
  public currentWeight: Number;
  public currentHumidity: Number;
  public F1_Temperature: HiveMeasurementEntry[];
  public F3_Weight: HiveMeasurementEntry[];
  public F4_Temperature: HiveMeasurementEntry[];
  public F5_Humidity: HiveMeasurementEntry[];
}
