import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ThingSpeakData } from '@shared/entities/thing-speak-data';
import { AppConfigService } from '@core/app-config';
import { switchMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HiveMeasurements } from '@shared/entities/hive-measurements';
import { HiveMeasurementEntry } from '@shared/entities/hive-measurement-entry';

@Injectable({
  providedIn: 'root'
})
export class DataRepositoryService {

  public constructor(private appConfig: AppConfigService,
    private httpClient: HttpClient) { }

  public getHiveData(): Observable<HiveMeasurements> {
    const result = this.appConfig.settings$.pipe(switchMap(settings => {
      // console.log(settings);
       const httpResult = this.httpClient.get<ThingSpeakData>(settings.services.thingSpeakUrl).pipe(map(r => {
       // const httpResult = this.httpClient.get<ThingSpeakData>('HiveData.json').pipe(map(r => {
        const hiveM = new HiveMeasurements();
        hiveM.channel = r.channel;

        const lastEntry = r.feeds.find(f => f.entry_id === r.channel.last_entry_id);
        if (lastEntry) {
          hiveM.lastUpdateDate = new Date(lastEntry.created_at);
        }

        hiveM.currentHiveTemperatur = +lastEntry.field1;
        hiveM.currentWeight = +lastEntry.field3;
        hiveM.currentTemperatur = +lastEntry.field4;
        hiveM.currentHumidity = +lastEntry.field5;

        hiveM.firstUpdateDate = new Date();
        hiveM.F1_Temperature = [];
        hiveM.F3_Weight = [];
        hiveM.F4_Temperature = [];
        hiveM.F5_Humidity = [];

        r.feeds.forEach(feedEntry => {
          const curDate = new Date(feedEntry.created_at);
          if (curDate < hiveM.firstUpdateDate) {
            hiveM.firstUpdateDate = curDate;
          }

            if (feedEntry.field1) {
              const newEntry = new HiveMeasurementEntry();
              newEntry.entryId = feedEntry.entry_id;
              newEntry.date = new Date(feedEntry.created_at);
              newEntry.value = +feedEntry.field1;

              hiveM.F1_Temperature.push(newEntry);
            }

            if (feedEntry.field3) {
              const newEntry = new HiveMeasurementEntry();
              newEntry.entryId = feedEntry.entry_id;
              newEntry.date = new Date(feedEntry.created_at);
              newEntry.value = Math.round(((+feedEntry.field3) + Number.EPSILON) * 100) / 100;

              hiveM.F3_Weight.push(newEntry);
            }

            if (feedEntry.field2) {
              const newEntry = new HiveMeasurementEntry();
              newEntry.entryId = feedEntry.entry_id;
              newEntry.date = new Date(feedEntry.created_at);
              newEntry.value = +feedEntry.field2;

              hiveM.F4_Temperature.push(newEntry);
            }

            if (feedEntry.field5) {
              const newEntry = new HiveMeasurementEntry();
              newEntry.entryId = feedEntry.entry_id;
              newEntry.date = new Date(feedEntry.created_at);
              newEntry.value = +feedEntry.field5;

              hiveM.F5_Humidity.push(newEntry);
            }

        });

        if (settings.environment.fixSensorJumps) {
          hiveM.F1_Temperature = this.filterOutJumps(hiveM.F1_Temperature, 5);
          hiveM.F3_Weight = this.filterOutJumps(hiveM.F3_Weight, settings.environment.weightJumpMaxDiff);
          hiveM.F4_Temperature = this.filterOutJumps(hiveM.F4_Temperature, 8);
          hiveM.F5_Humidity = this.filterOutJumps(hiveM.F5_Humidity, settings.environment.weightJumpMaxDiff);
        }

        return hiveM;
      }));
      return httpResult;
    }));
    return result;
  }

  public filterOutJumps(input: HiveMeasurementEntry[], maxDiff: number) : HiveMeasurementEntry[] {
    let result: HiveMeasurementEntry[] = [];

    if(!input || input.length <= 0){
      return result;
    }

      let previousValue = input[0];

      for(let i = 0; i < input.length; i++) {

        if(result.length >= 1) {
          previousValue = result[result.length - 1];
        }

        const curValue = input[i];
        const diff = Math.abs(previousValue.value - curValue.value);

        
        if(diff >= maxDiff) {

          if(i + 2 < input.length) {
            const nextDiff = Math.abs(input[i + 1].value - curValue.value);
            const nextnextDiff = Math.abs(input[i + 2].value - input[i + 1].value);

            if(nextDiff < maxDiff && nextnextDiff < maxDiff) {
              result.push(curValue);
            }
          }
        } 
        else {
          result.push(curValue)
        }
      }

    return result;
  }
}
