import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '@core/app-config';

@Component({
  selector: 'hive-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'dgo-hive-display';

public constructor(
  private appConfig: AppConfigService) { }

  public ngOnInit(): void {
    // It's safe to subscribe here becaue the AppComponent will be created only once
    // at the start of the application and lives until the app exists.
    this.appConfig.settings$.subscribe(settings => {
      // parse log level from app-config
      const logLevelString = (settings.environment.consoleLogLevel || 'ERROR').toUpperCase();
    });

  }
}
