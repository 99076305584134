import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AppConfig } from './app-config';

export function initConfigService(configService: AppConfigService): Function {
  // the provider factory expects a promise...
  return () => {
    return configService.settings$.toPromise();
  };
}

@Injectable()
export class AppConfigService {
  public settings$: Observable<AppConfig>;
  public constructor(private httpClient: HttpClient) {
    const jsonFile = 'appSettings.json';
    this.settings$ = this.httpClient.get(jsonFile).pipe(
      map(response => new AppConfig(response)),
      shareReplay(1)
    );
  }
}
