export enum systemType {
  production = 'prod',
  test = 'test'
}

export class AppConfig {
  // tslint:disable-next-line:no-any
  public constructor(object?: any) {
    if (object) {
      // tslint:disable-next-line:forin
      for (const prop in object) {
        this[prop] = object[prop];
      }
    }
  }

  public environment: {
    platformName: string;
    systemType: string;
    consoleLogLevel: string;
    fixSensorJumps: boolean;
    weightJumpMaxDiff: number;
    temperaturJumpMaxDiff: number;
    // seconds until a automatic refresh happens (request of new data)
    automaticRefreshSeconds: number;
  };

  public services: {
    thingSpeakUrl: string;
  };

  public chartSettings: {
    // number of days the last days weight component should display
    weightForLastDaysCount: number;
    // number of days to calculate the daily weight difference for
    weightDaysDiff: number;
    // number of entries to calculate the daily weight difference on
    weightDaysDiffNumberOfEntries: number;
    // number of days to show the long time temperatur chart for
    longTimeTemperaturDaysCount: number;
  };
}
